<!--
   Authors : beltosolution (Darie Suparno)
  Website : https://beltosolution.com/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://beltosolution.com/license
  Copyright and Good Faith Purchasers © 2020-present beltosolution.
 -->
<router-outlet>
    <app-spinner></app-spinner>
</router-outlet>